<template>
  <v-card-actions
    style="width: 100% !important; margin: 0 !important; padding: 0!important;"
  >
    <v-autocomplete
      v-model="selectedLocality"
      :items="modelList"
      :loading="modelQuerySearchSearching"
      :search-input.sync="modelQuerySearch"
      dense
      :multiple="false"
      rounded
      outlined
      solo
      flat
      hide-no-data
      background-color="#F0F0F1"
      :color="'success'"
      loader-height="5"
      :disabled="disabled || (sync && modelQuerySearchSearching)"
      hide-selected
      :item-value="itemValue"
      :item-text="itemText"
      :label="label"
      :hide-details="hideDetails"
      :placeholder="placeholder"
      return-object
      :error="wrongSelection || error"
      clearable
      :append-outer-icon="sync ? 'mdi-map-search' : undefined"
      class="my-0 py-0"
      @click:append-outer="syncSearch"
      @click:clear="onClearSearch"
    >
      <template v-slot:selection="data">
        <v-chip
          small
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          class="success"
          @click="data.select"
          @click:close="onClearSearch"
        >
          {{ data.item.title }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template>
          <v-list-item-content class="ml-2">
            <v-list-item-title>
              {{ data.item.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ `${data.item.address}` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <v-btn
                class="mx-0 px-0"
                text
                :color="parseInt(data.item.availableOffersNumber, 10) < 5 ? 'warning' : undefined "
              >
                {{ `Офферы: ${data.item.availableOffersNumber}` }}
              </v-btn>
              <v-btn
                class="mx-0 px-0 ml-4"
                text
                :color="parseInt(data.item.amount, 10) < 5000 ? 'warning' : undefined "
              >
                {{ `Баланс: ${data.item.amount}` }} ₽
              </v-btn>
              <!--              <v-card-actions>-->
              <!--                <span>-->
              <!--                  <span class="balance-value__title">Баланс:</span>-->
              <!--                  {{ `Баланс: ${data.item.amount}` }} ₽-->
              <!--                </span>-->
              <!--                <span class="ml-3">-->
              <!--                  <span class="balance-value__title">Офферы:</span>-->
              <!--                  {{ data.item.availableOffersNumber }}-->
              <!--                </span>-->
              <!--              </v-card-actions>-->
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <v-progress-circular
      v-if="modelQuerySearchSearching"
      indeterminate
      color="primary"
    />
  </v-card-actions>
</template>

<script>

export const ON_WBD_AUTOCOMPLETE_SEARCHING = 'wbdAutoCompleteSearching'
export const ON_WBD_AUTOCOMPLETE_SELECTED = 'wbdAutoCompleteSelected'
export const ON_WBD_AUTOCOMPLETE_INPUT = 'wbdAutoCompleteInput'
export const ON_WBD_AUTOCOMPLETE_CLEARED = 'wbdAutoCompleteCleared'
export const ON_WBD_AUTOCOMPLETE_LIST_UPDATED = 'wbdAutoCompleteListUpdated'

import ApiMixin from '@/mixins/api-mixin'
export default {
  name: 'WBDAutocompleteSearch',
  mixins: [ApiMixin],
  props: {
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'title'
    },
    label: {
      type: String,
      default: 'Поиск (от 3 букв)'
    },
    params: {
      type: Object,
    },
    modelType: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Поиск (от 3 букв)'
    },
    value: {
      type: Object,
    },
    sync: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    hideDetails: {
      type: Boolean,
    }
  },
  data: () => ({
    modelList: [],
    selectedLocality: '',
    modelQuerySearch: '',
    lastQuerySearch: null,
    modelQuerySearchSearching: false,
    wrongSelection: false,
  }),
  computed: {
    isOfficeSearch () {
      return this.modelType === this.modelTypeSet.MODEL_TYPE_OFFICE
    },
    isAddressSearch () {
      return this.modelType === this.modelTypeSet.MODEL_TYPE_ADDRESS
    },
    isOrganizationSearch () {
      return this.modelType === this.modelTypeSet.MODEL_TYPE_ORGANIZATION
    }
  },
  watch: {
    'modelQuerySearch': function (val) {
      if (!this.sync) {
        this.autocompleteSearch(val)
        this.$emit(ON_WBD_AUTOCOMPLETE_INPUT)
      }
    },
    'selectedLocality': function (val) {
      // todo on select callback
      this.$emit(ON_WBD_AUTOCOMPLETE_SELECTED, val)
    },
    'modelQuerySearchSearching': function (val) {
      // todo on select callback
      this.$emit(ON_WBD_AUTOCOMPLETE_SEARCHING)
    }
  },
  async created () {
    this.initSelectedValue(this.value)
  },
  methods: {
    onClearSearch () {
      // this.modelList = []
      this.selectedLocality = null
      this.modelQuerySearch = ''
      this.lastQuerySearch = null
      this.modelQuerySearchSearching = false
      this.$emit('wbdAutoCompleteCleared')
    },
    setModelList (modelData) {
      if (modelData && modelData.error) {
        this.modelList = []
        this.modelQuerySearchSearching = false
        if (this.isAddressSearch) {
          // this.showErrorNotification('Адрес не найден!')
        }
      } else {
        const regex = /ё/i
        if (this.isAddressSearch) {
          this.modelList = modelData.modelList.map((item) => ({
            ...item,
            [this.itemText]: `${this.lastQuerySearch}---${item[this.itemText].replace(regex, 'е')}`,
            [this.itemValue]: `${item[this.itemText]}`,
          }))
          console.log(this.modelList)
          if (this.sync) {
            this.modelQuerySearch = this.lastQuerySearch
          }
        }
        if (this.isOfficeSearch) {
          this.modelList = modelData.modelList.map((item) => ({
            ...item,
            [this.itemText]: `${this.lastQuerySearch}---${item.title.replace(regex, 'е')}`,
          }))
          console.log(this.modelList)
          if (this.sync) {
            this.modelQuerySearch = this.lastQuerySearch
          }
        } else if (this.isOrganizationSearch) {
          this.modelList = modelData.modelList.map((item) => {
            let title = item.title
            if (item.tradeMark) {
              title = `${title} (${item.tradeMark})`
            }
            if (item.root && item.root.fullName) {
              title = `${title} / ${item.root.fullName}`
            }
            return {
              ...item,
              title
            }
          })
        } else {
          this.modelList = modelData.modelList.map((item) => ({
            ...item,
            [this.itemText]: `${item[this.itemText].replace(regex, 'е')}`,
          }))
        }

        this.$emit(ON_WBD_AUTOCOMPLETE_LIST_UPDATED)
      }
    },
    getCallBack () {
      if (this.modelType === this.modelTypeSet.MODEL_TYPE_REGION) {
        return this.requestLocalityList
      }
      if (this.modelType === this.modelTypeSet.MODEL_TYPE_ORGANIZATION) {
        return this.requestOrganizationList
      }
      if (this.modelType === this.modelTypeSet.MODEL_TYPE_ADDRESS) {
        return this.searchAddresses
      }
      if (this.modelType === this.modelTypeSet.MODEL_TYPE_OFFICE) {
        return this.requestOfficeList
      }
    },
    decodeAddress (address) {
      if (!address) {
        return ''
      }
      const parts = address.split('---')
      if (parts.length > 1) {
        return parts[1]
      }
      return address
    },
    syncSearch () {
      this.autocompleteSearch(this.modelQuerySearch)
    },
    async autocompleteSearch (val) {
      try {
        if (!this.modelType) {
          return
        }
        // Items have already been loaded
        if (this.selectedLocality && this.selectedLocality.title === val) {
          return
        }
        if (!val) {
          return
        }
        // const clearedVal = val.replace(',', '')
        if (!val || val.trim() === '' || val.length < 3) {
          return
        }
        if (this.modelQuerySearchSearching) return
        this.modelQuerySearchSearching = true

        setTimeout(async () => {
          this.modelQuerySearchSearching = true
          this.lastQuerySearch = this.sync ? val : this.modelQuerySearch

          let defaultParams = {
            'page[number]': 1,
            'page[size]': 250,
            'searchQuery': this.sync ? val : this.lastQuerySearch.trim()
          }
          if (this.params) {
            defaultParams = {
              ...defaultParams,
              ...this.params
            }
          }

          await this.getCallBack()(this.setModelList, defaultParams)

          if (!this.sync) {
            this.modelQuerySearchSearching = false
            if (this.modelQuerySearch !== this.lastQuerySearch) {
              await this.autocompleteSearch(this.modelQuerySearch)
            }
          } else {
            this.modelQuerySearchSearching = false
            this.modelQuerySearch = val
          }
        }, this.sync ? 0 : 500)
      } catch (error) {
        console.log(error)
        if (this.isAddressSearch) {
          // this.showErrorNotification('Адрес не найден!')
        }
      }
    },
    initSelectedValue (value) {
      if (value) {
        this.modelList = [value]
        this.selectedLocality = value
      }
    },
    initModelList (modelList) {
      this.modelList = modelList || []
    }
  }
}
</script>
