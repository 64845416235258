<script>

export default {
  name: 'ApiMixin',
  methods: {
    async requestApiListData (action, params) {
      try {
        const response = await this.$store.dispatch(action, params)
        let paginatorTotal
        const modelList = response.data
        if (response.headers['x-total-count']) {
          paginatorTotal = parseInt(response.headers['x-total-count'], 10)
        } else {
          paginatorTotal = 50
        }

        return {
          modelList: modelList || [],
          total: paginatorTotal,
        }
      } catch (error) {
        console.log(error)
        return {
          modelList: null,
          total: null,
          error: true,
        }
      }
    },
    async requestOrganizationList (callback, params = {}, showLoader = false) {
    },

    async requestOfficeList (callback, params = {}) {
      const response = await this.$store.dispatch(
        'office/getOfficeList',
        { params })

      this.modelList = response.data.map((item) => ({
        ...item,
      }))


      const defaultParams = { 'page[number]': 1, 'page[size]': 100 }
      if (!params) {
        params = defaultParams
      } else {
        if (!params['page[number]']) {
          params['page[number]'] = 1
        }
        if (!params['page[size]']) {
          params['page[size]'] = 100
        }
      }

      const modelList = await this.requestApiListData(
        'office/getOfficeList', { params })

      if (callback) {
        callback(modelList)
      }
      return modelList
    },
    async searchAddresses (callback, params = {}) {},
  }
}

</script>
