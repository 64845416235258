<template>
  <div>
    <div
      v-if="show"
      class="office-select mr-2 mt-4"
    >
      <WBDAutocompleteSearch
        ref="searchOfficeFilterRef"
        :model-type="modelTypeSet.MODEL_TYPE_OFFICE"
        :value="officeSelected"
        :itemText="'search-key'"
        :label="'Поиск объектов по названию или адресу (от 3 символов)'"
        :placeholder="'Поиск объектов по названию или адресу (от 3 символов)'"
        @wbdAutoCompleteSelected="changeOffice"
        @wbdAutoCompleteCleared="clearOffice"
      />
      <v-autocomplete
        v-if="officeSelected === -101010101"
        v-model="officeSelected"
        class="office-select mr-2"
        :items="officeList"
        dense
        rounded
        outlined
        solo
        :color="'success'"
        :search-input.sync="searchQuery"
        return-object
        :loading="false"
        background-color="#F0F0F1"
        item-value="id"
        item-text="title"
        :placeholder="placeHolder"
        :multiple="false"
        :append-outer-icon="searchQuery || officeSelected ? 'fa-close' : undefined"
        flat
        hide-no-data
        hide-details
        @change="changeOffice"
        @click:append-outer="clearOffice"
      />
      <IconClose
        v-if="officeSelected"
        style="display: none"
        :width="18"
        :height="18"
        fill="red"
        @click.native="clearOffice"
      />
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/common/icons/IconClose'
import userUtil from '@/utils/user-util'
import { pluriaizeRelays, pluriaizeOffers } from '@/utils/plural-util'
import routeList from '@/router/labels'
import { saveToLocalStorage, removeFromLocalStorage } from '@/mixins/local_storage'
import WBDAutocompleteSearch from '@/components/common/autocomplete/index'

export default {
  name: 'OfficeSelect',
  components: {
    WBDAutocompleteSearch,
    IconClose
  },
  props: {
    requestEmpty: {
      type: Boolean,
      default: false,
    }
  },
  data: (vm) => ({
    searchQuery: '',
    placeHolder: 'Выберите объект',
    officeSelected: null,
    officeList: [],
  }),
  computed: {
    getUser () {
      return this.$store.state.users.info
    },
    show () {
      return userUtil.canSelectOffice(this.getUser)
    },
  },
  watch: {
    '$store.state.office.userOffice': function (office) {
      this.officeSelected = office
      this.updateAutocompleteValue()
    }
  },
  created () {
    const storeSelected = this.$store.state.office.userOffice
    if (storeSelected) {
      this.officeSelected = {
        ...storeSelected,
        'search-key': storeSelected.title
      }

      this.updateAutocompleteValue()
    }
    this.eventsBus.$on('UPDATE_OFFICE_LIST', this.fetchOffersList)

    this.fetchOffersList()
  },
  methods: {
    async fetchOffersList () {
      const pagination = { 'page[number]': 1, 'page[size]': 250 }
      const params = { ...pagination }

      const { data } = await this.$store.dispatch(
        'office/getOfficeList',
        { params })

      if (!data.length) {
        this.placeHolder = 'Объектов нет'
      }
      this.$store.commit('office/setAllOfficeItems', data)
      this.officeList = data.map((item) => {
        const titleParts = []
        const relayNumber = parseInt(item.activeRelays)
        const activeOfferNumber = parseInt(item.activeOffers)
        if (this.$route.name === routeList.OFFICE_OFFER_LIST_NAME && activeOfferNumber) {
          titleParts.push(`${activeOfferNumber} ${pluriaizeOffers(activeOfferNumber)}`)
        }
        if (this.$route.name === routeList.OFFICE_SHIFT_LIST_NAME && relayNumber) {
          titleParts.push(`${relayNumber} ${pluriaizeRelays(relayNumber)}`)
        }
        const stats = titleParts.length ? ` (${titleParts.join(', ')})` : ''
        return {
          ...item,
          title: `${item.title} ${stats}`,
        }
      })

      this.initAutocompleteModelList()
    },
    changeOffice (val) {
      if (!val) {
        return
      }
      this.officeSelected = val
      if (this.$route.params.id === this.officeSelected.id) {
        return
      }
      this.$store.dispatch('clearTableItems')

      saveToLocalStorage('office', this.officeSelected.id, true)
      this.$store.dispatch('office/fetchOfficeById', { officeId: this.officeSelected.id })
      this.$store.commit('office/setUserOffice', this.officeSelected)

      this.$router.push({
        name: this.$route.name,
        params: { id: this.officeSelected.id },
        query: { userId: this.$store.state.users.info.id }
      })
    },
    clearOffice () {
      if (this.searchQuery) {
        this.searchQuery = ''
      }
      this.officeSelected = null
      this.$store.dispatch('clearTableItems')
      this.$store.commit('office/setUserOffice', null)
      removeFromLocalStorage('office')

      this.$router.push({
        name: this.$route.name,
        params: {
          id: null
        }
      })
      this.initAutocompleteModelList()
    },
    updateAutocompleteValue () {
      const searchOfficeFilterRef = this.$refs.searchOfficeFilterRef
      if (searchOfficeFilterRef) {
        searchOfficeFilterRef.initSelectedValue(this.officeSelected)
      }
    },
    initAutocompleteModelList () {
      const searchOfficeFilterRef = this.$refs.searchOfficeFilterRef
      if (searchOfficeFilterRef) {
        if (this.officeSelected) {
          searchOfficeFilterRef.initModelList(
            [
              ...this.officeList.filter((item) => item.id !== this.officeSelected.id),
              this.officeSelected
            ]
          )
        } else {
          searchOfficeFilterRef.initModelList(this.officeList)
        }
      }
    }
  }
}
</script>

<style type="text/scss">
.office-select{
  max-width: 640px;
}
</style>
